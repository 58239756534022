// import "./style.css"

import "./merge/css/map.css"
import "./merge/css/resource.css"

//轮播图
import "./merge/css/special.css"
import "./merge/css/sliderx.css"
import "./merge/css/switchLayer.css"

import "./merge/bxSlider/js/jquery-1.9.1.min.js"
import "./merge/bxSlider/js/jquery.bxslider.min.js"
import "./merge/bxSlider/css/jquery.bxslider.css"


import "./merge/js/resource.js"
// import "./merge/js/sliderx.js"

// 地图组件
//import 'leaflet/dist/leaflet.css'
//import './merge/map/switchLayer.css'
//import LS from './merge/map/loadScatter'
//LS.init();
// import './indexAjax';

import BaseApp from 'components/base/baseApp';
import axios from 'axios';
import arcgis from 'components/portal/index';
import portalSource from 'components/data-gallery/portalSourceQueryString';
import 'bootstrap';
import ResourceGallery from './reource';
import ApplicationGallery from 'components/data-gallery/application-gallery';
import Carousel from 'components/application-carousel/index';
import * as login from 'components/login/login';




class App extends BaseApp{
    constructor() {
        super();
        this.bind();
        //获取登录信息
        var geoCodingUrl=config.geocodingPage;
	        if(login.check()){
	            geoCodingUrl=geoCodingUrl+'?token='+login.token();
				
	        }else{
	            geoCodingUrl=config.loginPage;
				
	        }
	        this.initSlider();
    }

    // 初始资源中心
    initResourceCenter(){
        new ResourceGallery();
    }
    
    initSlider(){
    	axios.get(config.urls.application_getall,{
            params: {
              type:2
            },
          }).then((result)=>{
          	var html = '';
          	for (var i = 0; i < result.data.data.rows.length; i++) {
			var imgurls ='';
			if(result.data.data.rows[i].snapurl){
				var snapurl = result.data.data.rows[i].snapurl;
				imgurls = config.loclUrl+'api/application/query/getApplicationsFile?fileName='+snapurl.substring(snapurl.lastIndexOf("\/") + 1, snapurl.length);
			}
  			html += '<div class="slide col-xs-12 col-sm-3"><a href="./appdetail.html?appid=' + result.data.data.rows[i].applicationid + '" target="_blank" style="text-decoration: none;">' +
            '<img style="width:100%" src="'+ imgurls+ '">' +
            '<p style="margin: 0;line-height: 34px;font-size: 16px;text-align: center;color: #4679b2;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;" title="' + result.data.data.rows[i].title + '">' + result.data.data.rows[i].title + '</p>' +
            '</a></div>';
            };
            $('.startSlide').html(html);
          	if (result.data.data.rows.length<= 4) {
	            $('.startSlide').bxSlider({
                    slideWidth: 223,
                    minSlides: 2,
                    maxSlides: 4,
                    moveSlides: 1,
                    startSlide: 0,
                    slideMargin: 30,
                    infiniteLoop: false,
                    controls: false
                });
	        } else {
	            $('.startSlide').bxSlider({
                    slideWidth: 223,
                    minSlides: 2,
                    maxSlides: 4,
                    moveSlides: 1,
                    startSlide: 0,
                    slideMargin: 30,
                });
	        }
          });
    }

    // 移动新闻列表
    moveUpNewsList(){
		
        var ul_news=document.getElementById('main-news-list');
        var offset=(this.newsOffset-1)+"px";
        this.newsOffset-=1;
        if(this.newsOffset==0-ul_news.clientHeight+24){
            this.newsOffset=0;
            offset="0px";
        }
        ul_news.style.marginTop=offset;
        var moveFunc=()=>{
            this.moveUpNewsList();
        }
        if(this.newsOffset%24==0){
            setTimeout(moveFunc,1000);
        }else{
            setTimeout(moveFunc,100);
        }
    }
    
    //初始新闻
    initNews(){
        return axios.get(config.urls.news_mainpage,).then((response)=>{
            if(response.data.code===200){
                var rows=response.data.data.rows;
				console.log(data)
                this.newsList=rows;
                this.newsIndex=0;
            
                // this.updateNews();
                var ul_news=document.getElementById('main-news-list');
                rows.forEach(function(newsItem){
                    var title=newsItem.title;
                    //避免因为新闻标题过长造成元素错位
                    if(title.length>30){
                        title=newsItem.title.substr(0,28)+'...';
                    }
                    var newsDate=newsItem.deploytime.split(' ')[0];
                    var linkurl=config.newsdetailPage+"?newsid="+newsItem.id;
                    var li_news=document.createElement('li');
                    li_news.classList.add("main-news-item");
                    li_news.innerHTML="<a href='"+linkurl+"'><span class='main-news-title'>"+title+"</span><span class='main-news-date'>"+newsDate+"</span></a>"
                    ul_news.appendChild(li_news);
                })
                //第一条多加一次
                var first=rows[0];
                var title=first.title;
                if(title.length>30){
                    title=first.title.substr(0,28)+'...';
                }
                var newsDate=first.deploytime.split(' ')[0];
                var linkurl=config.newsdetailPage+"?newsid="+first.id;
                var li_news=document.createElement('li');
                li_news.classList.add("main-news-item");
                li_news.innerHTML="<a href='"+linkurl+"'><span class='main-news-title'>"+title+"</span><span class='main-news-date'>"+newsDate+"</span></a>"
                ul_news.appendChild(li_news);
                this.newsOffset=0;
                var movefunc=()=>{
                    this.moveUpNewsList();
                }
                setTimeout(movefunc,1000);
                //启动新闻轮播
                // document.getElementsByClassName('main-news-item').onmouseover=()=>{
                //     clearInterval(intervalId);
                // }
                // document.getElementsByClassName('main-news-item').onmouseout=()=>{
                //     intervalId=setInterval(movefunc,3000);
                // }
            }else{
                // document.getElementById('main-news-list').innerHTML="<li><span class='main-news-errmsg'>公告加载失败...</span></li>";
            }
        }).catch(function(error){
            // document.getElementById('main-news-list').innerHTML="<li><span class='main-news-errmsg'>公告加载失败...</span></li>";
            // console.error(error);
        })
    }


     // 更新消息
    updateNews(){
        if(!this.newsList)
            return;
        var nextIndex=this.newsIndex+1;
        if(nextIndex===this.newsList.length){
            nextIndex=0;
        }
        var nextNews=this.newsList[nextIndex];
        if(!nextNews)
            return;
			
        document.getElementById('link-main-news').href=config.newsdetailPage+"?newsid="+nextNews.id;
		
        document.getElementById('news-title').innerHTML=nextNews.title;
        document.getElementById('news-time').innerHTML=nextNews.deploytime;
        this.newsIndex=nextIndex;
    }

    //初始化应用程序
    initTypicalApplication(){
		//轮播图
        const gallery= document.getElementById('typical-application1');
		console.log(gallery)
        axios.get(config.urls.application_getall,{
            params: {
              type:2
            },
          }).then((result)=>{
            new Carousel('typical-application1',result.data.data.rows);
			
			$(document).ready(function() {
				
			  var length = $(".content_1").children(".box").length;//盒子个数
			  // console.log($(".content_1"))
			  var boxWidth = $(".bigbox").width() / 4;//视窗宽度除以4获得移动宽度
			  var virtual = length * boxWidth;	//切换的临界点
			  var speed = 500;	//移动速度，速度建议要小于间隔时间的一半。
			  var time =400000;	//间隔时间
			  $(".box").width(boxWidth-3);
			
			  var Item = $('#switcher'); //要移动的元素
			  Item.css({ position: 'relative' }); //设置position
			  var move = boxWidth + 'px'; //移动的范围，是一个box的宽度。
			  var leftCriticalPoint = "-" + virtual + "px"; //有n个盒子，就以n个盒子的长度作为跳跃点
			
			  var flag = true;//点击允许
			
			  scrollContentStructure(length);
			
			  function scrollContentStructure(length) {
			    if(length < 4) {
			      $('#switcher').width(boxWidth * (length + 4)); //视窗宽度 条状体l+4，补体6-l；假设l=3，条状体7.补体3
			      if(length != 0) {
			        var content_1 = $(".content_1").html();
			        for(var i = 0; i < 6 - length; i++) {
			          $(".content_1").append(content_1); //最少6个盒子，补够
			        }
			      }
			    } else {
			      $('#switcher').width(virtual * 2);
			      $(".content_2").html($(".content_1").html()); //复制
			    }
			  }
			
			  if(length != 0) {
			    var callback = setInterval(moving, time);
			  }
			
			  function moving() {
			    flag = false;
			    if(Item[0].style.left == leftCriticalPoint) {
			      Item[0].style.left = "0px";
			    }
			    Item.animate({ left: '-=' + move }, speed, function() {
			      if(Item[0].style.left == leftCriticalPoint) {
			        Item[0].style.left = "0px";
			      }
			    });
			    flag = true;
			  }
			
			  $("li").click(function() {
			    //当前处于动画状态及可点击状态判断
			    //标志位防止事件栈积累，
			    if(!Item.is(":animated") && flag) {
			      var left = Item[0].style.left;
			      clearInterval(callback);
			
			      if($(this).index() == 1) {
			        if(left == leftCriticalPoint) {
			          Item[0].style.left = "0px";
			        }
			        Item.animate({ left: '-=' + move }, speed, function() {
			          if(Item[0].style.left == leftCriticalPoint) {
			            Item[0].style.left = "0px";
			          }
			          callback = setInterval(moving, time);
			        });
			        // console.log("右");
			      } else if($(this).index() == 0) {
			        if(isNaN(parseInt(left)) || left == "0px") {
			          Item[0].style.left = leftCriticalPoint;
			        }
			        Item.animate({ left: '+=' + move }, speed, function() {
			          if(Item[0].style.left == "0px") {
			            Item[0].style.left = leftCriticalPoint;
			          }
			          callback = setInterval(moving, time);
			        });
			        // console.log("左");
			      }
			    }
			  });
			
			})
			
			
        });
		
		

//         function bindControl(dataLength){
//             let index=0;
//             document.getElementById('carousel-left').addEventListener('click',(e)=>{
//                 e.preventDefault();
//                 if(index===0){
//                     return;
//                 }
//                 index--;
//                 if(index===0){
//                     e.target.classList.remove('enable');
//                 }
//                 document.getElementById('carousel-right').classList.add('enable');
//                 animate(gallery,'right',300);
//             })
//     
//             document.getElementById('carousel-right').addEventListener('click',(e)=>{
//                 e.preventDefault();
//                 if(index>=dataLength-4){
//                     return;
//                 }
//                 index++;
//                 if(index>=dataLength-4){
//                     document.getElementById('carousel-right').classList.remove('enable');
//                 }
//                 document.getElementById('carousel-left').classList.add('enable');
//                 animate(gallery,'left',300);
//             })
//         }
// 
//         function animate(target,direction,distance){
//             let moved=0;
//             const step=20;
//             const time= setInterval(()=>{
//                 const marginleft= parseInt(target.style.marginLeft)||0;
//                 if(direction==='left'){
//                     target.style.marginLeft=marginleft-step+'px';
// 
//                 }
//                 else{
//                     target.style.marginLeft=marginleft+step+'px';
//                 }
// 
//                 moved+=step;
//                 if(moved===distance){
//                     clearInterval(time);
//                 }
//             },30)
//         }

        return this;
    }


    bind(){
		
        // document.getElementById('fulltextSearch-btn').onclick=()=>{
        //     var queryStr=document.getElementById('fulltextSearch-text').value;
        //     queryStr=escape(queryStr);
        //     if(queryStr&&queryStr.length>0){
        //         window.open(config.fulltextSearchPage+"?wd="+queryStr);
        //     }
        // }
    }
}




	new App();
	
