// 选项卡

// 开发资源
let tabA = document.querySelectorAll(".dropdown a"),
    actionsTab = tabA[0],
    preShow = document.getElementById('api-resource')


// console.log(preShow)

for (let i = 0; i < tabA.length; i++) {

    tabA[i].addEventListener('click', function () {
		
        actionsTab.id = "";
        this.id = "actions";
        let resourceId = this.getAttribute('data-target');
        preShow.style.display = 'none';
        let currentShow = document.getElementById(resourceId);
        currentShow.style.display = 'block';
        actionsTab = this;
        preShow = currentShow;

    })
}


