module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\r\n\r\n';
var util=require("components/utils/dateUtils").default;;
__p += '\r\n	\r\n';
 itemArray.forEach(function(item){;
__p += '\r\n    <div class="box">\r\n	\r\n            <div class="" name="' +
((__t = (item.name)) == null ? '' : __t) +
'">\r\n		\r\n			\r\n            <a href="' +
((__t = (item.linkurl)) == null ? '' : __t) +
'" target="_blank">\r\n                <img src="' +
((__t = (item.snapurl)) == null ? '' : __t) +
'" class="img-responsive" alt="maponline-test-save" οnerrοr="this.src=\'' +
((__t = (require('./001.jpg'))) == null ? '' : __t) +
'\'">\r\n				  <p class="thumbnail-label text-overflow"> ' +
((__t = (item.title)) == null ? '' : __t) +
'</p>\r\n            </a>\r\n			\r\n            \r\n        </div>\r\n		\r\n    </div>\r\n    ';
});


}
return __p
}