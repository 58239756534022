import applicationTemplate from './application-template.ejs';

import './style.css';

const DISTENCE_INTERNAL=300;

/**
 *轮播组件，暂时仅供首页典型应用轮播
 *
 * @class Carousel
 */
class Carousel{
    
    constructor(domId,rows){
        this.gallery= document.getElementById(domId);

        if(rows.length<=4){
            const html=applicationTemplate({itemArray:rows});
            this.gallery.innerHTML=html;
            return;
        }

        const newRows=this.recompose(rows);
        this.gallery.style.width=newRows.length*DISTENCE_INTERNAL+'px';

        const html=applicationTemplate({itemArray:newRows});
        this.gallery.innerHTML=html;

        //从第4个开始播放（从0开始计）
        this.index=4;
        // this.gallery.style.marginLeft=(-1)*this.index*DISTENCE_INTERNAL+'px'

        const length=newRows.length;
        this.bind(length);
        this.autoPlay(length);
    }


    /**
     *
     *  把数组前4个复制到最后，把数组最后4个复制到最前面，组成新的数组
     * @param {*} rows
     * @returns
     * @memberof Carousel
     */
    recompose(rows){
        const firstThree=rows.slice(0,4);
        const lastThree=rows.slice(rows.length-4);

        return lastThree.concat(rows).concat(firstThree);
    }

    bind(length){
        document.getElementById('carousel-left').addEventListener('click',(e)=>{
            e.preventDefault();
            this.prev(length);
        })

        document.getElementById('carousel-right').addEventListener('click',(e)=>{
            e.preventDefault();
            this.next(length);
        })
    }

    prev(length){
        const animated= this.animate(this.gallery,'right',DISTENCE_INTERNAL,length);

    }

    next(length){
        const animated= this.animate(this.gallery,'left',DISTENCE_INTERNAL,length);
    }

    animate(target,direction,distance,length){
        let moved=0;
        const step=distance/20; //分20帧完成这个动画
        const marginleft= parseInt(target.style.marginLeft)||0;
        if(marginleft%distance!==0){
            //动画正在执行时不执行其他动画，直到这个动画完成
            return false;
        }
        const time= setInterval(()=>{
            const marginleft= parseInt(target.style.marginLeft)||0;
            if(direction==='left'){
                target.style.marginLeft=marginleft-step+'px';
            }
            else{
                target.style.marginLeft=marginleft+step+'px';
            }

            moved+=step;
            if(moved===distance){
                clearInterval(time);
                if(direction==='left'){
                    this.index++;
                    if(this.index>=length-4){
                        this.gallery.style.marginLeft=-4*DISTENCE_INTERNAL+'px';
                        this.index=4;
                    }
                }
                else{
                    this.index--;
                    if(this.index<=0){
                        this.gallery.style.marginLeft=(4*2-length)*DISTENCE_INTERNAL+'px';
                        this.index=length-4*2;
                    }
                }
            }
        },30);
    }

    autoPlay(length){
        const time=setInterval(()=>{
            this.next(length);
        },3000);
    }
}

export default Carousel;