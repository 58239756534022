import OneMapGallery from "components/data-gallery/resource-onemap-gallery"
import PortalMapsGallery from "components/data-gallery/resource-portalmaps-gallery"
import FunctionGallery from "components/data-gallery/resource-functionservices-gallery"
import DocumentGallery from "components/data-gallery/resource-document-gallery"

import axios from "axios"

class Components {
  constructor() {
    this.init()
  }

  init() {
    new OneMapGallery({
      queryParam: {
        cataloguuids: config.resource_service_category_id
      },
      containerId: "tab0",
      num: 4,
      sortField: "regdate",
      sortOrder: "desc",
      template: "",
      listStyle: "card"
    })

    new PortalMapsGallery({
      queryParam:
        'orgid:0123456789ABCDEF -type:"Layer" -type: "Map Document" -type:"Map Package" -type:"Basemap Package" -type:"Mobile Basemap Package" -type:"Mobile Map Package" -type:"ArcPad Package" -type:"Project Package" -type:"Project Template" -type:"Desktop Style" -type:"Pro Map" -type:"Layout" -type:"Explorer Map" -type:"Globe Document" -type:"Scene Document" -type:"Published Map" -type:"Map Template" -type:"Windows Mobile Package" -type:"Layer Package" -type:"Explorer Layer" -type:"Geoprocessing Package" -type:"Desktop Application Template" -type:"Code Sample" -type:"Geoprocessing Package" -type:"Geoprocessing Sample" -type:"Locator Package" -type:"Workflow Manager Package" -type:"Windows Mobile Package" -type:"Explorer Add In" -type:"Desktop Add In" -type:"File Geodatabase" -type:"Feature Collection Template" -type:"Code Attachment" -type:"Featured Items" -type:"Symbol Set" -type:"Color Set" -type:"Windows Viewer Add In" -type:"Windows Viewer Configuration"  -type:"web mapping application" -type:"Layer Package" (type:"Project Package" OR type:"Windows Mobile Package" OR type:"Map Package" OR type:"Basemap Package" OR type:"Mobile Basemap Package" OR type:"Mobile Map Package" OR type:"Pro Map" OR type:"Project Package" OR type:"Web Map" OR type:"CityEngine Web Scene" OR type:"Map Document" OR type:"Globe Document" OR type:"Scene Document" OR type:"Published Map" OR type:"Explorer Map" OR type:"ArcPad Package" OR type:"Map Template") NOT owner:{esri TO esri_zzzzz}',
      containerId: "tab1",
      num: 4,
      listStyle: "card"
    })

    new FunctionGallery({
      queryParam:
        "tags:空间分析服务 OR tags:几何服务 NOT owner:{esri TO esri_zzzzz}",
      containerId: "tab2",
      num: 4,
      listStyle: "card"
    })

    new OneMapGallery({
      //首页展示文档服务
      queryParam: {
        cataloguuids: config.document_service_category_id
      },
      containerId: "tab3",
      num: 4,
      sortField: "regdate",
      sortOrder: "desc",
      template: "",
      listStyle: "card"
    })
  }
}

export default Components
